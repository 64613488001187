<template>
<div class="editprofile-container">
    <div class="top">
        <div class="header">
            <h2 style="margin: 0;">{{ $t('profile.editProfile') }}</h2>
            <p style="margin: 0;">{{ $t('profile.userData') }}</p>
        </div>
        <div class="fields">
            <div class="field">
                <p>{{ $t('common.name') }}</p>
                <vs-input block v-model="user.name" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p>{{ $t('common.surname') }}</p>
                <vs-input block v-model="user.surname" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p>{{ $t('common.country') }}</p>
                <LazyDropdown v-model="user.country_id" :value="user.country_id" endpoint="/places/countries"  :placeholder="$t('profile.clickAndType')"/>
            </div>

            <div class="field">
                <p>{{ $t('common.city') }}</p>
                <LazyDropdown v-model="user.city_id" :value="user.country_id" endpoint="/places/cities" :placeholder="$t('profile.clickAndType')"/>
            </div>
            
            <div class="field">
                <p>{{ $t('common.email') }}</p>
                <vs-input block disabled style="opacity: 1;" v-model="user.email" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p>{{ $t('common.birthdate') }}</p>
                <vs-input block type="date" v-model="user.birthdate" />
            </div>
            <div class="field">
                <p>{{ $t('common.gender') }}</p>
                <vs-select v-model="user.gender" block :placeholder="$t('profile.clickAndType')">
                    <vs-option :label="$t('common.male')" value="_MALE">
                        {{ $t('common.male') }}
                    </vs-option>
                    <vs-option :label="$t('common.female')"  value="_FEMALE">
                        {{ $t('common.female') }}
                    </vs-option>
                    <vs-option :label="$t('common.other')"  value="_OTHER">
                        {{ $t('common.other') }}
                    </vs-option>
                </vs-select>
            </div>
        </div>
    </div>
    <vs-button size="large" :loading="loading" @click="editUserEvent">{{ $t('common.saveChanges').toUpperCase() }}</vs-button>

</div>
</template>

<script>

import { apiCall } from '../../utils/ApiMiddleware';
import LazyDropdown from '../LazyDropdown.vue';
import {GlobalEventEmitter} from '../../utils/GlobalEventEmitter';
export default {
    name: "editProfile",
    props: {},
    components: { LazyDropdown },
    data() {
        return {
            loading: false,
            user: {
                name: '',
                surname: '',
                gender: '',
                birthdate: '',
                language_id: 0,
                country_id: '',
                city_id: '',
                want_newsletter: false,
                billing_details: null,
                email: ''
            },
            countries: []
        }
    },

    mounted(){
        this.getUser();
    },

    methods: {
        async getUser() {
            let response = await apiCall('GET', '/users/info');
            if (response.status == 200) {
                this.user.name = response.data.name;
                this.user.surname = response.data.surname;
                this.user.gender = response.data.gender != null && response.data.gender != '' ? "_" + response.data.gender : ''; //workaround vsOption
                this.user.birthdate = response.data.birthdate != null ? response.data.birthdate.split('T')[0] : null;
                if(response.data.country != null){
                    this.user.country_id = response.data.country.id;
                }
                if(response.data.city != null){
                    this.user.city_id = response.data.city.id;
                }
                this.user.want_newsletter = response.data.want_newsletter;
                this.user.email = response.data.email;
            }
        },

        async editUserEvent(){
            this.loading = true;
            await this.editUser();
            this.loading = false;

        },

        async editUser(){
            // if user name is empty show an error message
            if (this.user.name == '') {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.messages.nameEmpty'),
                    color: 'danger',
                    position: 'top-right'
                })
                return;
            }
            // if user surname is empty show an error message
            if (this.user.surname == '') {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.messages.surnameEmpty'),
                    color: 'danger',
                    position: 'top-right'
                })
                return;
            }
            let response = await apiCall('PATCH', '/users/info', {
                name: this.user.name,
                surname: this.user.surname,
                gender: this.user.gender.replace("_", ''),
                birthdate: this.user.birthdate != null ? this.user.birthdate + 'T00:00:00-00:00' : null, //RF3339NANO
                country_id: this.user.country_id != '' && this.user.country_id != null ? this.user.country_id : null,
                city_id: this.user.city_id != '' && this.user.city_id != null ? this.user.city_id : null,
                want_newsletter : this.user.want_newsletter,
            });
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('profile.messages.saved'),
                    color: 'success',
                    position: 'top-right'
                })
                GlobalEventEmitter.$emit('loadUserMeta')

            } else if(response.status != 0){
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.messages.cannotSave'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
        }

    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.field p {
    width: 35%;
    max-width: 35%;
}

.editprofile-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

/* MOBILE */
@media (max-width: 600px) {
    .editprofile-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px!important;
        box-sizing: border-box;

    }

}
</style><style>
.editprofile-container .field .vs-input {
    background: transparent !important;
    font-size: 1rem;
}

.editprofile-container .field .vs-select__input {
    background: transparent !important;
    font-size: 1rem;
}

.editprofile-container .field input::-webkit-date-and-time-value {
    text-align: left !important;
    font-size: 1rem;
}

.editprofile-container input.vs__search::placeholder, .vs-input__label, .vs-select__label{
    color: #747474 !important;
    font-size: 15px !important;
    opacity: 1!important;
    font-family: 'Montserrat', sans-serif!important;
    font-weight: 500!important;
}

.editprofile-container .vs-input:focus~.vs-input__label:not(.vs-input__label--placeholder):not(.vs-input__label--label){
    opacity: 0!important;
}

.editprofile-container .vs-select__label {
    margin-top: 10px!important;
}

</style>
